import * as React from "react";
import "./Atomics.scss";
import { inputEvent } from "../../utils/types";
import {useState} from "react";
import {cilX} from "@coreui/icons";
import {IconButton} from "./index";

type inputProps = React.DetailedHTMLProps<
  React.InputHTMLAttributes<HTMLInputElement>,
  HTMLInputElement
>;
interface Props extends inputProps {
  className?: string;
  type?: React.InputHTMLAttributes<HTMLInputElement>["type"];
  onChange?: (e: inputEvent) => void;
  onKeyPress?: (e: inputEvent) => void;
}

const Input: React.FC<Props> = ({
  className,
  type,
  onChange,
  onKeyPress,
  autoFocus,
  value,
  defaultValue,
  required ,
  placeholder,
}) => {
  const [isShow, setIsShow] = React.useState(false)

  const handleShow = () => {
    setIsShow(pre => !pre)
  }
  return (
      <>
        {type === "password" ? (
            <div className={`input-group mb-3 ${className}`}>
              <input
                  value={value}
                  defaultValue={defaultValue}
                  autoFocus={autoFocus}
                  onKeyPress={onKeyPress}
                  onChange={onChange}
                  type={isShow ? 'text' : type}
                  className={`form-control input-select-custom border-0`}
                  style={{
                    // backgroundColor: "transparent",
                    border: '1.5px solid #505050',
                  }}
                  required={required ? required : false}
              />
              <span className="input-group-text" id="basic-addon2">
                {isShow ? <i role="button" className="fa fa-eye" onClick={handleShow} /> : <i role="button" className="fa fa-eye-slash" onClick={handleShow} />}
              </span>
            </div>
        ) : (
            <input
                value={value}
                defaultValue={defaultValue}
                autoFocus={autoFocus}
                onKeyPress={onKeyPress}
                placeholder={placeholder}
                onChange={onChange}
                type={type}
                className={`input-custom border-0 ${className}`}
                required={required ? required : false}
            />
        )}
      </>
  );
};

export default Input;
